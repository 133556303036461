<template>
  <div>
    <v-container class="fill-height">
      <v-row>
        <v-col cols="12" md="10">
          <v-row class="mt-12">
            <h1>
              <v-col
                cols="12"
                class="font-weight-bold white--text"
                :class="$vuetify.breakpoint.mdAndUp ? 'display-1 ': 'headline'"
              >
                KONTAKT
              </v-col>
            </h1>
          </v-row>
          <v-row v-show="showContent">
            <v-col cols="12">
              <v-card
                class="alert-card contact"
                tile
                flat
              >
                <v-card-text class="white--text body-1 font-weight-light py-6 pl-6">
                  <v-row>
                    <v-col class="divider mx-3 mb-8 mt-0"></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="2" class="font-weight-bold">
                      Anschrift:
                    </v-col>
                    <v-col>
                      <v-row no-gutters>
                        <v-col class="font-weight-bold" cols="12">Widerstand und Söhne GmbH</v-col>
                        <v-col cols="12">Prittwitzstr. 100</v-col>
                        <v-col cols="12">89075 Ulm</v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="divider mx-3 mb-8 mt-0"></v-col>
                  </v-row>
                  <v-row dense class="mt-3">
                    <v-col class="font-weight-bold" cols="12" md="2">
                      Email:
                    </v-col>
                    <v-col cols="10">
                      info@widerstand.digital
                    </v-col>
                    <v-col class="font-weight-bold" cols="12" md="2">
                      Telefon:
                    </v-col>
                    <v-col cols="10">
                      +49 160 95 144 808
                    </v-col>
                    <v-col class="font-weight-bold" cols="12" md="2">
                      Facebook:
                    </v-col>
                    <v-col cols="10">
                      fb.com/widerstandundsoehne
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="divider mx-3 mb-8 mt-3"></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <Location style="border-left: 8px solid #ffffff; border-radius: 0px;"></Location>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div class="divider"></div>
                      </v-col>
                      <v-col cols="12">
                        <v-img src="/img/wus_05.jpg"></v-img>
                      </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense v-show="!showContent">
            <v-col>
              <v-icon color="white" class="mr-2">mouse</v-icon><v-icon color="white">360</v-icon>
            </v-col>
          </v-row>
          <v-row class="white--text" v-show="!showContent">
            <v-col>
              <v-icon color="white" class="mr-2">mouse</v-icon>x1 ===<v-icon class="ml-2" color="white">report_problem</v-icon>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import { mapMutations, mapGetters } from 'vuex'
import Location from '@/components/Location.vue'

export default {
  name: 'contact',
  components: {
    Location: Location
  },
  data: () => ({
    showIntroTooltip: false
  }),
  methods: {
    ...mapMutations({
      setStep: 'SET_STEP'
    })
  },
  computed: {
    ...mapGetters([
      'showContent'
    ])
  }
}
</script>
