<template>
  <div>
    <v-container class="fill-height">
      <v-row>
        <v-col cols="12" md="10">
          <v-row class="mt-12">
            <h1>
              <v-col
                cols="12"
                class="font-weight-bold white--text"
                :class="$vuetify.breakpoint.mdAndUp ? 'display-1 ': 'headline'"
              >
                PROJEKTE
              </v-col>
            </h1>
          </v-row>
          <v-row v-show="showContent">
            <v-col>
              <v-card
                class="alert-card projects"
                tile
                dark
                flat
              >
                <v-card-text class="white--text body-1 font-weight-light">
                  <v-row>
                    <v-col cols="12">
                      <v-btn icon @click="projectCount === 0 ? projectCount = projects.length - 1 : projectCount--">
                        <v-icon>arrow_back</v-icon>
                      </v-btn>
                      {{projectCount + 1}} / {{projects.length}}
                      <v-btn icon @click="projectCount === (projects.length - 1) ? projectCount = 0 : projectCount++">
                        <v-icon>arrow_forward</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col class="display-1" cols="12">
                      {{projects[projectCount].text}}
                    </v-col>
                  </v-row>
                  <v-carousel touchless :value="projectCount" hide-delimiters :show-arrows="false" height="auto">
                    <v-carousel-item
                      v-for="project in projects"
                      :key="project.name"
                    >
                        <v-row>
                          <v-col cols="12">
                            <v-carousel
                              height="auto"
                              hide-delimiter-background
                              show-arrows-on-hover
                              style="background-color: rgba(0,0,0,0.5) !important"
                            >
                              <v-carousel-item
                                v-for="pic in project.pics"
                                :key="pic"
                              >
                                <v-img
                                  contain
                                  max-height="600"
                                  :src="'/img/projects/' + pic"
                                ></v-img>
                              </v-carousel-item>
                            </v-carousel>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="font-weight-bold mt-6">
                            TL;DR
                          </v-col>
                          <v-col class="font-italic" cols="12">
                            <v-alert
                              text
                              tile
                              v-html="project.tdlr"
                            >
                            </v-alert>
                          </v-col>
                          <template
                            v-for="(info, i) in project.info"
                          >
                            <v-col
                              :key="i + 'title'"
                              class="font-weight-bold"
                              cols="12"
                              v-html="info.title"
                            >
                            </v-col>
                            <v-col
                              :key="i + 'text'"
                              cols="12"
                              v-html="info.text"
                            >
                            </v-col>
                          </template>
                          <v-col class="font-weight-bold  " cols="12">
                          Links
                        </v-col>
                        <v-col
                          v-for="link in project.links"
                          :key="link.name"
                          cols="12"
                        >
                          <a class="white--text" target="_blank" :href="link.link"><v-icon class="mr-3">link</v-icon>{{link.name}}</a>
                        </v-col>
                        </v-row>
                    </v-carousel-item>
                  </v-carousel>
                  <v-row class="pb-6 pt-0 mt-2">
                    <v-col>
                      <router-link v-if="projectCount === projects.length - 1" to="/kunden">
                        <v-btn
                          @click="setStep(3)"
                          class="elevation-0"
                          dark
                          tile
                          color="error"
                        >
                          Kunden
                          <v-icon small class="ml-3">
                            arrow_downward
                          </v-icon>
                        </v-btn>
                      </router-link>
                      <v-btn
                        v-else
                        @click="++projectCount"
                        dark
                        tile
                        class="elevation-0"
                        color="error"
                      >
                        Nächstes Projekt
                        <v-icon small class="ml-3">
                          arrow_forward
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense v-show="!showContent">
            <v-col>
              <v-icon color="white" class="mr-2">mouse</v-icon><v-icon color="white">360</v-icon>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'projects',
  data: () => ({
    projects: [
      {
        name: 'pfandkasten',
        text: 'PFANDKASTEN',
        tdlr: 'Einen interaktiver Spendenkasten mit analogem Charme, Social-Media- und Web-Anbindung in einer Open-Air-Bar am Ulmer Donauufer.',
        pics: [
          'PK_1.jpg',
          'PK_2.jpg',
          'PK_3.jpg',
          'PK_4.jpg',
          'PK_5.jpg'
        ],
        links: [
          {
            name: 'pfandkasten.de',
            link: 'https://pfandkasten.de'
          }
        ],
        info: [
          {
            title: 'Funktionsweise',
            text: 'Nach dem Einwurf einer Pfandmarke macht der Pfandkasten nach Zustimmung ein Foto des Spenders. Dieses wird anschließend, falls gewünscht, auf der Wall-of-Fame auf Facebook und auf der zugehörigen Website veröffentlicht. Außerdem wird das Foto - neben dem aktuellen Spendenstand und einem zufällig ausgewählten Spruch - im Pfandkasten auf modifizierten Kassenbon-Druckern ausgegeben.'
          },
          {
            title: 'Gestaltung',
            text: 'Beim Interaktionskonzept des Pfandkastens wurde bewusst auf gängige Technologien wie Touch-Eingaben oder digitale Anzeigen verzichtet. Stattdessen wurde auf charmante analoge und mechanische Lösungen gesetzt, was sich z.B. in den von Elektromotoren angesteuerten Schildern und den eingesetzten Druckern wiederspiegelt.'
          },
          {
            title: 'Technische Umsetzung',
            text: 'Die gesamte Installation wurde als dezentrales Internet-of-things-System umgesetzt, so dass alle Elemente wie Knöpfe, Kamera, Drucker, Motoren und LEDs netzwerkbasiert miteinander kommunizieren und damit räumlich getrennt sein können. Zudem wurde eine Schnittstelle für die Anbindung an Social-Media-Plattformen, sowie die Pfandkasten-Webseite implementiert. Ein Live-Stream der Drucker wird an ausrangierte Fernseher in einem weiteren Kasten außerhalb der Bar gesendet.'
          }
        ]
      },
      {
        name: 'ulmer-nest',
        text: 'ULMER NEST',
        tdlr: 'Das Ulmer Nest ist ein Pilotprojekt, bei dem neue Wege zur Unterstützung bereits vorhandener Maßnahmen zum Erfrierungsschutz obdachloser Menschen gefunden und erprobt werden sollen.',
        pics: [
          'UN_4.jpg',
          'UN_1.jpg',
          'UN_2.jpg',
          'UN_3.jpg'
        ],
        links: [
          {
            name: 'ulmernest.de',
            link: 'https://ulmernest.de'
          }
        ],
        info: [
          {
            title: 'Ziel',
            text: 'Das Ulmer Nest ist die Erprobung einer Ergänzung zum bestehenden Erfrierungsschutz in der Stadt Ulm. Es ist eine Notzuflucht in Form einer Schlafkapsel, die obdachlose Menschen in besonders kalten Nächten vor lebensbedrohlichen Temperaturen, Wind und Nässe schützen soll.'
          },
          {
            title: 'Zielgruppe',
            text: 'Insbesondere sollen hier diejenigen aufgefangen werden, denen es aus einer Vielzahl an Gründen nicht möglich ist, die bestehenden Maßnahmen wie Sammelunterkünfte oder Übernachtungsheime zu nutzen. Das Ulmer Nest bietet für diese durch alle Raster gefallenen Menschen als Notfallinstrument in letzter Instanz einen niederschwelligen Schutz für Leib und Leben und gilt als Alternative zu einer ungeschützten Übernachtung im Freien.'
          },
          {
            title: 'Funktionsweise',
            text: 'Um die Sicherheit der Nutzer, aber auch alle Begleiterscheinungen einer Übernachtungsstätte, wie Müll und Verunreinigungen bestmöglich zu kontrollieren, gleichzeitig aber den dafür zu leistenden Aufwand minimal zu halten, wurde ein Konzept entwickelt, das auf dem Einsatz von Sensoren und einer einfach zu bedienenden, geschlossenen Online-Plattform zur Verständigung beteiligter Akteure basiert.<br><br>Sensoren für GPS, Temperatur und Luftfeuchtigkeit, Rauch und CO2, Bewegungserfassung im Innenraum, der elektronischen Überprüfung der Verriegelung von Innen, laufen gemeinsam mit einer Grundbeleuchtung, einem Alarm-Signal-Summer und einem Wärmetauscher, bzw. Belüftungssystem in einer zentralen Steuereinheit zusammen.<br><br>Die gesammelten Sensorwerte werden dort erfasst, gespeichert und abhängig von der Verfügbarkeit via LoraWAN oder GSM an einen Datenserver übertragen, wo diese Werte der zugehörigen Online-Plattform zur verfügung gestellt werden.<br><br>Über letzere werden Akteure informiert, wenn das Nest in Benutzung ist, sodass dieses am nächsten Tag kontrolliert und bei Bedarf repariert oder gereinigt werden kann.'
          }
        ]
      },
      {
        name: 'burgpilz-lueftungssteuerung',
        text: 'BURGPILZ LÜFTUNGSSTEUERUNG',
        tdlr: 'Eine Lüftungssteuerung zur Generierung optimaler klimatischer Bedingungen für die Pilzzucht.',
        pics: [
          'BP_3.jpg',
          'BP_2.jpg',
          'BP_1.jpg'
        ],
        links: [
          {
            name: 'burgpilz.de',
            link: 'https://burgpilz.de'
          }
        ],
        info: [
          {
            title: 'Ausgangslage',
            text: 'In den Gewölbekellern der Ulmer Wilhelmsburg werden seit kurzem professionell verschiedene Pilz-Sorten in Bio-Qualität produziert. Um stets optimale und gleichbleibende Bedingungen zur Aufzucht der Pilze herzustellen, wurden die unterschiedlichen Aufzuchträume mit Sensoren und Aktoren ausgestattet. Die Sensoren messen Temperatur, Luftfeuchtigkeit und CO2-Gehalt der Luft und innerhalb des Pilzsubstrats. Die Aktoren regulieren die Werte mittels Lüftung oder Befeuchtung der Luft.'
          },
          {
            title: 'Technik-Setup',
            text: 'Sensoren sind über einen 1-Wire-Bus mit Arduinos verbunden. Hier werden die Daten ausgewertet und gegebenenfalls die Aktoren angesteuert. Zudem können die aktuellen Daten über ein Touch-Display angezeigt und die Aktoren manuell angesteuert werden. Alles Arduinos (in verschiedenen Räumen) kommunizieren via MQTT mit einem Raspberry PI, der als Server fung(h)iert, die Daten persistent speichert und diese über eine Web-Oberfläche in Diagrammen abrufbar macht. Zudem kann die Steuerung über die Web-Oberfläche konfiguriert werden, beispielsweise weitere Sensoren hinzugenommen / geändert oder entfernt.'
          }
        ]
      },
      {
        name: 'losgehts',
        text: 'LOS GEHT\'S DIGITAL',
        tdlr: 'Eine individualisierbare Plattform für digitale Messen und Video-Beratungen',
        pics: [
          'LG_1.jpg'
        ],
        links: [
          {
            name: 'Hin und Weg 2021',
            link: 'https://online.hinundweg-ulm.de'
          },
          {
            name: 'Future4You Bildungsmesse 2021',
            link: 'https://online.future4you-bc.de'
          }
        ],
        info: [
          {
            title: 'Die Funktion',
            text: 'Über die Plattform "Los geht\'s digital" können digitale Messen abgehalten und Beratungen mit Hilfe einer integrierten Text- und Video-Chat-Funktion abgehalten werden. Über eine intuitive Benutzeroberfläche können Aussteller*innen und Nutzer*innen eingeladen und verwaltet werden, die dann wiederum Ihre eigenen Stände, Mitarbeiter und Profile selbstständig pflegen können. Während des vorgegebenen Messezeitraums können Video-Chat-Beratungen an den Ständen angefragt, organisiert und durchgefphrt werden werden.'
          },
          {
            title: 'Individualisierung',
            text: 'Die Plattform ist über eine einfache grafische Oberfläche individualisierbar. So kann das Erscheinungsbild mit wenigen Klicks farblich und auch strukturell angepasst werden.'
          },
          {
            title: 'Weitere Features',
            text: 'Es können Live-Voträge angelegt und inklusive einer Kommentarfunktion in die Plattform integriert werden. Es können Fragebögen für verschiedene Nutzergruppen angelegt und automatisiert versendet und ausgewertet werden.'
          },
          {
            title: 'Technische Umsetzung',
            text: 'Um maximale Skalierbarkeit auch bei einen Besucherzahlen zu gewährleisten sind die einzelnen Funktionalitäten auf unterschiedliche Services und Module aufgebaut. Statische Dateien, Mediendateien und Datenbanken sind jeweils voneinander separiert und von der Server-Logik unabhängig. Letztere kann trotz der vorhandenen Echtzeitfunktionalität dank eines Broker-Dienstes im Hintergrund sowohl horizontal als auch vertikal problemlos skaliert werden. Grundlage der Plattform bilden Node.js und Vue.js.'
          }
        ]
      },
      {
        name: 'internationale-botschaft',
        text: 'INTERNATIONALE BOTSCHAFT',
        tdlr: 'Konzeption und Umsetzung einer Online-Plattform zur Bürgerkommunikation mit angebundenen interaktiven Installationen auf dem Hans und Sophie Scholl Platz in der Ulmer Stadtmitte.',
        pics: [
          'IB_4.jpg',
          'IB_3.jpg',
          'IB_1.jpg',
          'IB_2.jpg'
        ],
        links: [
          {
            name: 'ulm-internationalestadt.de',
            link: 'http://www.ulm-internationalestadt.de/ulm-internationale-stadt/internationale-botschaft.html'
          }
        ],
        info: [
          {
            title: 'Online-Plattform',
            text: 'Über ein Online-Formular können Bürger stadtbezogene, internationale Kleinanzeigen aufgeben und dabei eigene Fähigkeiten, Unternehmungen, Interessen anbieten oder suchen. Dabei wählen sie aus einem von sieben vorgegebenen Labels einen Themenbereich aus (z.B. internationaler Lebensstil, digitales Leben, Beruf, …). Die Ad erhält automatisiert eine Chiffre und die Kontaktdaten werden anonymisiert. Der Kontakt zwischen einer Ad und Antworten auf die Ad wird direkt durch die Initiatoren der Aktion vermittelt. Über einen Administrations-Bereich können die Ads verwaltet und freigegeben werden. Freigegebene Ads erscheinen zudem auf einer Weltkarte, dabei wird die Ad an dem Ort dargestellt, den der Autor der Ad als sein Herkunftsland angegeben hat.'
          },
          {
            title: 'Interaktiver Wegweiser',
            text: 'Auf dem Dach der Botschaft ist ein großes Schild mit LED-Laufschrift installiert. Dieses zeigt den Herkunftsort des Autors der letzten eingegangenen Ads, sowie die Entfernung zu diesem Ort von der Botschaft ausgehend an. Zudem dreht sich das Schild in die entsprechende Himmelsrichtung.'
          },
          {
            title: 'Displays',
            text: 'Im Inneren der Botschaft sind zwei Displays angebracht, von denen eines die aktuell eingehenden Ads und das andere eine statistische Auswertung der verwendeten Themenbereiche darstellt. Beide Displays werden in Echtzeit aktualisiert.'
          }
        ]
      },
      {
        name: 'ulmer-unke',
        text: 'ULMER UNKE',
        tdlr: 'Eine Webseite mit einer integrierten Plattform zur digitalen Verwaltung von Bücher-Ausleihen, Bewertungen und Nutzern.',
        pics: [
          'UU_2.jpg',
          'UU_1.png',
          'UU_3.png'
        ],
        links: [
          {
            name: 'ulmerunke.de',
            link: 'https://ulmerunke.de'
          }
        ],
        info: [
          {
            title: 'Ulmer Unke?',
            text: 'Die Ulmer Unke ist ein im Jahr 2013 ins Leben gerufener Kinder- und Jugendbuchpreis. Die Jury besteht aus ca. 150 Kindern und Jugendlichen zwischen 10 und 14 Jahren, die in einem vorgegebenen Zeitraum Neuerscheinungen lesen und bewerten.'
          },
          {
            title: 'Die Plattform',
            text: 'Neben dem öffentlich zugänglichen Teil der Webseite, in der Informationen rund um die Ulmer Unke zu finden sind und die zur Auswahl stehenden Bücher vorgestellt werden, existiert auch ein geschlossener Bereich für die Jury-Mitglieder, sowie das Organisationsteam.<br><br>Hier können Bücher verliehen, bestehende Ausleihen verlängert, Bücher und Nutzer verwaltet, Bewertungen abgegeben und die Rahmenbedingunen definiert werden.<br><br>Die Plattform ist ohne ein bestehendes Content-Management-System individuell entwickelt worden, so dass zu 100% auf die Bedürfnisse des Unke-Teams und der Jury-Mitglieder eingegangen werden konnte.'
          },
          {
            title: 'Der Vorteil',
            text: 'Anstatt wie bisher mühsam mit verschiedenen Excel-Tabellen, Anmeldeformularen und Bewertungen (weit über 500) digital und analog hantieren zu müssen, sind nun alle Informationen zentral verfügbar und lassen sich bequem aufbereiten: Die Bücher können nach mehreren Kriterien (Bewertung, Anzahl der Bewertungen, ...) sortiert werden, die Ausleihen sind jederzeit einsehbar und nachvollziehbar, die Bücherinformationen lassen sich effizient verwalten.<br><br>Zur Dokumentation können Informationen wie Nutzerdaten oder auch die Bewertungen vom Organisations-Team im Excel-Format heruntergeladen werden.'
          }
        ]
      },
      {
        name: 'si-more',
        text: 'SI-MORE SPIEGEL',
        tdlr: 'Ein interaktiver Spiegel mit integriertem Display, einer kabellosen Kamera und Infrarot-Fernbedienung.',
        pics: [
          'SMS_1.jpg',
          'SMS_2.jpg',
          'SMS_3.jpg',
          'SMS_4.jpg'
        ],
        links: [
          {
            name: 'si-more-spiegel.de',
            link: 'https://www.si-more-spiegel.de/'
          }
        ],
        info: [
          {
            title: 'Das Konzept',
            text: 'Der Si-More-Spiegel wurde bereits bei der ProSieben-Show "Das Ding des Jahres" als praktischer Problemlöser präsentiert: Der Spiegel verfügt über ein integriertes Display, in dem man sich über den Live-Feed einer Kamera auch komfortabel von hinten betrachten kann.<br><br>Der Si-More-Spiegel findet sowohl im Einzelhandel, wie auch in Privathaushalten Anwendung.'
          },
          {
            title: 'Das System',
            text: 'Für die zweite Generation des Si-More-Spiegels haben wir ein kabelloses Kamera-/Spiegel-Setup entwickelt, das über einen eigenen gesicherten WLAN-Hotspot kommuniziert. Zudem haben wir für den Spiegel eine per Fernbedienung steuerbare Benutzeroberfläche implementiert, mit der beispielsweise Kamerapositionen gespeichert und abgerufen, oder auch eigenen Bildern als Slideshow auf dem Spiegel-Monitor abgespielt werden können. Zum Schutz vor plötzlichem Stromwegfall haben wir Mechanismen verbaut, die genügend Strom zwischenspeichern um das Gerät ordnungsgemäß herunterzufahren und bei Wiederkehr der Stromzufuhr wieder zu starten.<br><br>Für die Produktion der Geräte im Betrieb wurde ein einfach zu bedienendes Werkzeug entwickelt, mit dem jeweils eine Kamera und ein Spiegel über einen gemeinsamen Key verbunden werden und diese Information dokumentiert wird.'
          },
          {
            title: 'Erweiterungen',
            text: 'Für den Einzelhandel haben wir das System zusätzlich mit einem Präsenzmelder ausgestattet, der den Bilschirminhalt von einer bliebigen Medienquelle zum Kamerbild schaltet, sobal eine Person vor den Spiegel tritt.<br><br>Das System ist modular entwickelt, so dass zukünftigen Erweiterungen bis hin zum internetfähigen Smart-Mirror nichts im Wege steht.'
          }
        ]
      },
      {
        name: 'stadtjugendring-ulm-webseite',
        text: 'STADTJUGENDRING ULM WEBSEITE',
        tdlr: 'Eine Webseite für den Stadtjugenring Ulm, dessen Einrichtungen und einer integrierten Mitglieder-Plattform.',
        pics: [
          'SJR_1.jpg',
          'SJR_2.jpg',
          'SJR_3.jpg',
          'SJR_4.jpg',
          'SJR_5.jpg'
        ],
        links: [
          {
            name: 'sjr-ulm.de',
            link: 'https://sjr-ulm.de'
          }
        ],
        info: [
          {
            title: 'Die Aufgabe',
            text: 'Der Stadtjugenring Ulm e.V. trat mit dem Wunsch an uns heran die eigene Kommunikation und Darstellung nach außen hin zu optimieren. Dies haben wir in Form einer Online-Plattform umgesetzt, mit deren Hilfe nicht nur der Webauftritt neu strukturiert und inszeniert wurde, sondern auch die Mitglieds-Organisationen des Stadtjugenrings, sowie deren Veranstaltungen in den Mittelpunkt gestellt werden. So soll der Stadtjugendring zukünftig als erste Anlaufstelle für Engagement und Angebote im Kinder- und Jugendbereich in der Region Ulm dienen.'
          },
          {
            title: 'Die Plattform',
            text: 'Um die Aufgabe zu erfüllen wurde ein Teil der Webseite als von den Mitglieds-Organisationen selbst verwaltet konzipiert: Diese können sich an der Plattform anmelden und mittels einer Micro-Site vorstellen, auf vorhandene Kommunikations-Kanäle verweisen, um neue Mitglieder werben und Neuigkeiten und Veranstaltungen einstellen.<br><br>Intern besteht die Möglichkeit sich über ein scharzes Brett auszutauschen und gegenseitig zu unterstützen. Der Stadtjugenring hat über die Nutzerverwaltung stets Zugriff auf die aktuellen Kontaktdaten der Mitglieds-Organisationen.<br><br>Die Plattform wurde individuell ohne ein bestehendes Content-Management-System entwickelt und kann somit schnell und flexibel angepasst und erweitert werden.'
          },
          {
            title: 'Weitere Möglichkeiten',
            text: 'Mit der Bündelung aller Organisationen in einer gemeinsamen Plattform, besteht die Möglichkeit auch über die Plattform zu kommunizieren und beispielsweise Stimmungsbilder oder Umfragen darüber abzubilden, zukünftige Mitglieder vorab vorzustellen und wichtige Informationen zu übermitteln.'
          }
        ]
      },
      {
        name: 'wilhelmsbar',
        text: 'WILHELMSBAR',
        tdlr: 'Eine Bar / Biergarten / Bühne auf Zeit im Hof der Ulmer Wilhelmsburg.',
        pics: [
          'WB_1.jpg',
          'WB_4.jpg',
          'WB_5.jpg',
          'WB_2.jpg',
          'WB_3.jpg'
        ],
        links: [
          {
            name: 'wilhelmsbar.de',
            link: 'http://wilhelmsbar.de'
          }
        ],
        info: [
          {
            title: 'Das Konzept',
            text: 'Die Wilhelmsbar feierte im Rahmen des Pop Up Space 2018 ihr Debut. 2019 ging es in die zweite Runde und es hieß wieder "Stürmt die Burg"! Der Innenhof der Wilhelmsburg wurde durch selbst konzipertes und gebautes Mobiliar, Bühne und Beleuchtung wieder zur frei bespielbaren Austragungsstätte für unterschiedlichste Veranstaltungen. Nicht zuletzt folgte auch das Programm dem experimentellen Grundgedanken eines „Pop-Up“ Spektakels.'
          },
          {
            title: 'Wieso?',
            text: 'Mit diesem Projekt hatten wir die Möglichkeit uns den Jugendtraum von der eigenen Kneipe zu erfüllen und unseren Horizont zu erweitern. Mit freier Hand, viel Herzblut und Schweiß konnten wir eine eigene kleine Welt schaffen und einen bisher weitestgehend ungenutzten Ort beleben.'
          }
        ]
      },
      {
        name: 'infera-klimaregler',
        text: 'INFERA KLIMAREGLER',
        tdlr: 'Umsetzung eines Klimareglers von der Hardware-Entwicklung über die Treiber bis zum User-Interface.',
        pics: [
          'IK_3.jpg',
          'IK_2.jpg',
          'IK_1.jpg'
        ],
        links: [
          {
            name: 'infera.de',
            link: 'https://www.infera.de/'
          }
        ],
        info: [
          {
            title: 'Das Projekt',
            text: 'Neben der Konzeption und Begleitung der Hardware-Entwicklung und der Treibersoftware der verschiedenen Sensoren haben wir in diesem Projekt auch die Software des Klimareglers implementiert und für die Bedienung über ein 7-Zoll-Touchdisplay optimiert.<br><br>Der Klimaregler wertet diverse Sensordaten aus und entscheidet darauf hin über den Lüftungszustand, der aber auch manuell gesetzt werden kann. Alle Daten werden für eine mögliche spätere Auswertung aufgezeichnet.'
          },
          {
            title: 'Module',
            text: 'Die Software wurde modular konzipiert, so dass sie nach den Wünschen und Anforderungen des jeweiligen Kunden konfiguriert werden kann. Neben dem Klimaregler-Modul sind so auch ein einfaches Temperatur-Anzeige-Modul, eine Zeitschaltuhr, ein Aufzeichnungsmodul, Module zur Anzeige von Strom und Spannung, sowie eine Vielzahl an Einstellungsmöglichkeiten vorhanden.'
          }
        ]
      },
      {
        name: 'hans-lebrecht-schule',
        text: 'HANS-LEBRECHT-SCHULE WEBSITE',
        tdlr: 'Eine einladende Website für die Hans-Lebrecht-Schule in Ulm, einem sonderpädagogischem Bildungs- und Beratungszentrum.',
        pics: [
          'HLS_1.jpg',
          'HLS_2.jpg',
          'HLS_3.jpg',
          'HLS_4.jpg'
        ],
        links: [
          {
            name: 'hans-lebrecht-schule.de',
            link: 'https://hans-lebrecht-schule.de/'
          }
        ],
        info: [
          {
            title: 'Die Aufgabe',
            text: 'Die Hans-Lebrecht-Schule bat uns um eine übersichtliche Website, in der eine Vielzahl von Informationen einfach zugänglich ist. Die Herausforderung bestand darin, unterschiedlichen Zielgruppen (insbesondere Lehrer*innen und Schüler*innen mit Eltern) nur ihnen Relevantes zu zeigen und entsprechend aufzubereiten.<br><br>Da es sich bei der Hans-Lebrecht-Schule um ein sonderpädagogisches Bildungs- und Beratungszentrum handelt, war es der Schulleitung ein besonderes Anliegen, einladend und freundlich zu erscheinen, um dem Engagement und der pädagogischen Hingabe der Belegschaft gerecht zu werden.'
          },
          {
            title: 'Das Konzept',
            text: 'Wir kommen dem Wunsch nach einer außergewöhnlichen Menüführung nach, indem wir die Navigationsleiste untypischerweise nicht im oberen Seitenbereich platzieren, sondern als große Schaltflächen mit einzigartigen Icons in der Seitenmitte platzieren. Von dort gelangen Besucher*innen nicht nur zu den allgemeinen Informationen, sondern auch zu den zielgruppenspezifischen Seiten.<br><br>Diese unterscheiden sich nicht nur inhaltich, sondern auch in Textsprache und der Art der Bilder und Grafiken. Mittels unterschiedlicher Farbcodierungen geben wir zusätzliche Unterstützung bei der Orientierung.'
          }
        ]
      },
      {
        name: 'tanz-mit-dem-tiger',
        text: '"TANZ MIT DEM TIGER" AR TANZPERFORMANCE',
        tdlr: 'Eine augmented reality Tanzperformance in Zeiten von "Social Distancing" kombiniert reale und virtuelle Tänzer*innen.',
        pics: [
          'TMDT_1.jpg',
          'TMDT_2.jpg',
          'TMDT_3.jpg',
          'TMDT_4.jpg'
        ],
        links: [
          {
            name: 'roxy.ulm.de/tanzlabor',
            link: 'https://www.roxy.ulm.de/tanzlabor/tanzlabor.php'
          }
        ],
        info: [
          {
            title: 'Warum?',
            text: 'Die Pandemie durch das Sars-Cov-2-Virus führte zu großen Einschränkunge des Alltags der Menschen. Insbesondere litten Künstler*innen unter der Stillegung des kulturellen Lebens und sahen sich mit Existenzängsten konfrontiert. Gleichzeitig machte die Digitalisierung in Deutschland einen längst notwendigen Schritt nach vorne und so entstand die Idee, eine reale Tanzperformance mit dem Virtuellen zu kombinieren, sodass Tanz und Kultur auch mit Kontakteinschränkungen aufblühen können.'
          },
          {
            title: 'Was?',
            text: 'Der "Tanz mit dem Tiger" ist eine Verschmelzung von Virtualität und Realität. Zu Beginn machen virtuelle Avatare die Zuschauer*innen mit den Begebenheiten vertraut und stellen sich in ruhigen Momenten künstlerisch vor. Zwei reale Tänzer*innen betreten behutsam den Raum und nehmen vorsichtig Kontakt zu den virtuellen Avataren auf. Aus dem anfänglichen Nebeneinander entwickelt sich ein Miteinander. Die Choreographie gipfelt in einem stillen Finale, das die perfekte Verschmelzung aller Tänzer*innen inszeniert, bevor sich die virtuellen Tänzer*innen in Nichts auflösen und alles wie ein kurioser Traum erscheint.'
          },
          {
            title: 'Wie?',
            text: 'Möglich wird die aufwendige Choreographie durch das Motion-Capturing-Verfahren. Insgesamt vier Tänzer*innen aus Hong Kong, Brasilien und den USA mit jeweils ganz eigenen Stilen haben ihre Choreographien mit dieser Technologie aufgezeichnet und uns als Daten zukommen lassen. Gemeinsam mit ihnen wurden virtuelle Avatare erstellt, die entweder exakte Abbildungen oder eine alternative Version ihrer selbst waren. Zusammen mit der Musik, die eigens für dieses Projekt komponiert wurde, war die Erstellung einer Zeitleiste Kernbestandteil des Projekts. Sekundengenau muss die Planung sein, wann welcher Avatar wo eine Tanzchoreographie ausführt.<br><br>Reale Tänzer*innen ergänzen die Show und interagieren mit Avataren, die sie selbst nicht sehen können. Eine Änderung an der virtuellen Choreographie führte also stets auch zu einer "Störung" der realen Choreographie. Eine Belastung für die realen Tänzer*innen, die durch frühe Planung vermieden wurde.<br><br>Spezielle Lichter signalisieren dem Publikum jederzeit, wo sich gerade ein virtueller Avatar befindet. Zusätzlich hängt eine 360°-Kamera über der Raummitte, die kostenlos in\'s Internet streamt und einen Einblick in die Show gewährt.'
          },
          {
            title: 'Und der Name?',
            text: 'Ursprung für den Titel ist ein Zitat von Dr. Christian Drosten aus dem Mai 2020. Er beschreibt den Umgamg während der Corona-Pandemi mit den Worten "Es geht jetzt darum, Stück für Stück herauszufinden, wo man die Zügel des Tieres lockern kann, ohne dass es gleich auf einen losgeht."<br>In diesem Sinne sieht der Regisseur von "Tanz mit dem Tiger" es als Traumabewältigung, aus dem Leiden von Kulturschaffenden, die die Einschränkungen des öffentlichen Lebens wie keine andere Branche zu spüren bekamen, etwas Positives zu ziehen. Mit ihrer Stärke - ihrer Kreativität - soll etwas Neues geschaffen werden. Etwas, das die Menschen auch in Zeiten der Kontaktbeschränkungen zusammenbringen kann.'
          }
        ]
      },
      {
        name: 'ball-design',
        text: 'BALL-DESIGN WEBSITE',
        tdlr: 'Eine aufgeräumte Website für eine Grafik-Designerin mit einer übersichtlichen Darstellung des Portfolios und einfacher Kontaktmöglichkeit.',
        pics: [
          'BD_1.jpg',
          'BD_2.jpg',
          'BD_3.jpg'
        ],
        links: [
          {
            name: 'ball-design.de',
            link: 'https://ball-design.de/'
          }
        ],
        info: [
          {
            title: 'Das Ziel',
            text: 'Eine moderne Website auf Basis der Konzeptzeichnungen der Grafik-Designerin. Ein Schmankerl stellt dabei die Startseite dar. Die Illustration eines Gesichts, mit animierter Augenbewegung. Gestochen scharf als Vektorgrafik und auch dann noch funktionsfähig, wenn der Bildschirm sehr klein ist.'
          },
          {
            title: 'Das gewisse Etwas',
            text: 'Ein ansprechendes und symmetrisches User-Interface war uns bei dieser Website sehr wichtig. Der aufgeräumte Inhalt sollte eingebettet sein in schöne Animationen und einer verständlichen Navigation.'
          }
        ]
      },
      {
        name: 'grenzwandler',
        text: 'GRENZWANDLER APP',
        tdlr: 'Die Grenzwandler App ermöglicht multimediale und standortbasierte Führungen am "Grünen Band".',
        pics: [
          'GW_1.jpg',
          'GW_2.jpg'
        ],
        links: [
          {
            name: 'grenzwandler.app',
            link: 'https://www.grenzwandler.app/'
          },
          {
            name: 'Youtube: Grenzwandler-Trailer',
            link: 'https://www.youtube.com/watch?v=WKpwz6WtnVQ'
          }
        ],
        info: [
          {
            title: 'Die App',
            text: 'Der Grenzwandler ist ein interaktiver und standortbasierter Multimedia-Guide. Insgesamt drei Routen im Grünen Band erzählen Geschichten der Deutschen Teilung und der Orte an der ehemaligen innerdeutschen Grenze. Der Grenzwandler führt Benutzer*innen mit aufwendig animierten Videos vor Ort durch die Geschichte.'
          },
          {
            title: 'Das technische Gerüst',
            text: 'Die App wird häufig spontan heruntergeladen, da erst Schilder vor Ort auf sie aufmerksam machen. Daher war es oberstes Ziel, die App in Hinblick auf ihre Dateigröße so kompakt wie möglich zu machen. Die jeweiligen Touren und Medieninhalte werden erst bei Bedarf heruntergeladen, sodass die Benutzer*innen stets die Kontrolle über den Datenverbrauch behalten. Dank einer gemeinsamen Code-Basis schaffen wir eine identische Erfahrung sowohl auf Android- als auch auch iOS-Geräten, wodurch auch der Wartungsaufwand besonders gering ausfällt. Die Touren können beliebig hinzugefügt, geändert oder gelöscht werden, ohne eine Aktualisierung der eigentlichen App vornehmen zu müssen.'
          }
        ]
      },
      {
        name: 'weltwandler',
        text: 'WELTWANDLER APP',
        tdlr: 'Die Weltwandler App ermöglicht interaktive und standortbasierte Führungen an der Wilhelmsburg mit Geschichten von Zeitzeugen.',
        pics: [
          'WW_1.jpg',
          'WW_2.jpg',
          'WW_3.jpg',
          'WW_4.jpg',
          'WW_5.jpg',
          'WW_6.jpg'
        ],
        links: [
          {
            name: 'mfg.de/aktuelles/details/2153-das-naechste-level-fuer-vr-games',
            link: 'https://www.mfg.de/aktuelles/details/2153-das-naechste-level-fuer-vr-games/'
          }
        ],
        info: [
          {
            title: 'Das Konzept',
            text: 'Der Weltwandler ist eine augmented reality App und selbstversorgende Plattform, die Zugang zu immersiven Touren ermöglicht. Egal wo.<br><br>Die App erlaubt eine interaktive Erfahrung, die unterschiedlichste Formen von Medien auf einem Mobilgerät wiedergeben kann und am besten mit Kopfhörern erlebt wird. Der Weltwandler ist aber auch eine Hardware-Plattform, entwickelt um die augmented reality Erfahrung an jedwedem Ort erlebbar zu machen. Alle digitalen Medien sind auf einem lokalen Medien-Server gespeichert, welcher vollständig autark ohne Zugang zu Internet oder Stromnetz betrieben wird. Emissionsfrei durch die Nutzung von Solarenergie und einem muskelbetriebenen Generator.<br><br>Das Projekt entstand im Rahmen von "VRNOW" vom Animationsinstitut der Filmakademie Baden-Württemberg mit Förderung durch die MFG, der Medien- und Filmgesellschaft Baden-Württemberg.'
          },
          {
            title: 'Die Geschichten',
            text: 'Zeitzeugen erzählen ihre persönlichen Geschichten von ihrer Zeit auf der Wilhemsburg. Polnische Zwangsarbeiterinnen schildern ihre Rückkehr nach Ulm; zurück an die Wilhemsburg, das für sie ein Ort des Schreckens gewesen ist. Währenddessen berichten Ulmer*innen, wie die Burg ihnen neue Hoffnung gab, nachdem sie ihr Zuhause im Krieg verloren hatten. Der fiktive Erzähler - der Wilhelmsbär - begleitet die Benutzer*innen dabei auf ihrer Reise durch Raum und Zeit. Die Geschichten werden mit aufwendig animierten Videos, Archivbildern und interaktiven augmented reality Elementen erzählt.'
          },
          {
            title: 'Die Technik',
            text: 'Wir verwenden sowohl für Android- als auch für iOS eine gemeinsame Code-Basis. Dies vereinfacht die Entwicklung der App. Für die Interaktion mit augmented reality Elementen bedarf es sogenannter Marker: Zweidimensionale, markante Bilder, wie zum Beispiel einem Lageplan der Wilhelmsburg auf der Infotafel.<br><br>Da die Tour auch an Orten verläuft, an denen es kein GPS-Signal gibt, werden kleine Bluetooth-Sender verwendet, die zur Bestimmung der Position und zum Aktivieren der Inhalte dienen. Diese Sender halten mit ihrer Batterie rund anderthalb Jahre.<br><br>Essenzieller Bestandteil des Projektes ist ein Generator, welcher ohne Verbindung zum Internet oder Stromnetz an jedweder Stelle platziert werden kann. Dieser Generator liefert mit Solarenergie dem Strom, um einen kleinen, autarken Medien-Server zu versorgen. Die App verbindet sich automatisch mit dieser und beginnt umgehend mit dem Download der Mediendaten. Die Tour ist startbereit, sobald diese vollständig heruntergeladen wurden.'
          }
        ]
      }
    ],
    projectCount: 0
  }),
  mounted () {
    const index = this.projects.map(obj => obj.name).indexOf(this.$route.params.project)
    if (index !== -1) {
      this.projectCount = index
    } else {
      this.$router.replace({ params: { project: this.projects[0].name } })
    }
  },
  methods: {
    ...mapMutations({
      setStep: 'SET_STEP'
    })
  },
  computed: {
    ...mapGetters([
      'showContent'
    ])
  },
  watch: {
    projectCount () {
      if (this.$route.params.project !== this.projects[this.projectCount].name) {
        this.$router.replace({ params: { project: this.projects[this.projectCount].name } })
      }
    }
  }
}
</script>
