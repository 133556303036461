<template>
  <div>
    <v-container class="fill-height">
      <v-row>
        <v-col cols="12" md="10">
          <v-row class="mt-12">
            <h1>
              <v-col
                cols="12"
                class="font-weight-bold white--text"
                :class="$vuetify.breakpoint.mdAndUp ? 'display-1 ': 'headline'"
              >
                KUNDEN
              </v-col>
            </h1>
          </v-row>
          <v-row v-show="showContent">
            <v-col cols="12">
              <v-card
                class="alert-card customers body-1"
                tile
                flat
              >
                <v-card-text class="white--text body-1 font-weight-light">
                  <v-row class="py-6">
                    <v-col>
                      Wir sind glücklich eine Vielzahl unterschiedlichster Kunden, von denen nachfolgend einige aufgelistet sind, mit sehr unterschiedlichen Aufgabenstellungen betreuen zu dürfen ...
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-for="customer in customers" :key="customer.pic" cols="6" sm="3" md="3" class="text-center">
                      <v-card
                        flat
                        tile
                        class="fill-height grid-card"
                        >
                        <v-container class="fill-height">
                          <v-row align="center">
                            <v-col cols="12">
                              <a target="_blank" :href="customer.link">
                                <v-img class="customer" height="100px" contain :src="'/img/customers/' + customer.pic + '.svg'"></v-img>
                              </a>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-col>
                      <router-link to="/kontakt">
                        <v-btn
                          @click="setStep(4)"
                          dark
                          tile
                          color="error"
                          class="elevation-0"
                        >
                          Weiter zum Kontakt
                          <v-icon small class="ml-3">
                            arrow_downward
                          </v-icon>
                        </v-btn>
                      </router-link>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense v-show="!showContent">
            <v-col>
              <v-icon color="white" class="mr-2">mouse</v-icon><v-icon color="white">360</v-icon>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'customers',
  data: () => ({
    customers: [
      { pic: 'acd', link: 'https://www.acd-gruppe.de/' },
      { pic: 'cadul', link: 'https://www.cad-ul.de/' },
      { pic: 'gardena', link: 'https://www.gardena.com/de/' },
      { pic: 'gramm', link: 'http://www.gramm-germany.de/' },
      { pic: 'hls', link: 'https://hans-lebrecht-schule.de' },
      { pic: 'infera', link: 'https://www.infera.de/' },
      { pic: 'invisium', link: 'http://invisium.io/' },
      { pic: 'keicher', link: 'https://www.keicher.de/' },
      { pic: 'klb', link: 'https://www.klb-koetztal.de/' },
      { pic: 'legoland', link: 'https://www.legoland.de/' },
      { pic: 'roxy', link: 'https://roxy.ulm.de/' },
      { pic: 'sjr', link: 'https://sjr-ulm.de/' },
      { pic: 'ulm', link: 'https://www.ulm.de/' },
      { pic: 'utikal', link: 'https://www.utikal-automation.com/de/' },
      { pic: 'zuken', link: 'https://www.zuken.com/de/' },
      { pic: 'zwickroell', link: 'https://www.zwickroell.com/de/' }
    ]
  }),
  methods: {
    ...mapMutations({
      setStep: 'SET_STEP'
    })
  },
  computed: {
    ...mapGetters([
      'showContent'
    ])
  }
}
</script>
