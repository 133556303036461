<template>
  <div>
    <v-container class="fill-height">
      <v-row>
        <v-col cols="12" md="10">
          <v-row :class="showContent ? 'my-12' : 'mt-12 mb-3'">
            <h1>
              <v-col
                cols="12"
                class="font-weight-bold white--text"
                :class="$vuetify.breakpoint.mdAndUp ? 'display-1 ': 'headline'"
              >
                WILLKOMMEN IM
              </v-col>
              <v-col
                style="white-space: nowrap"
                class="font-weight-bold white--text"
                :class="$vuetify.breakpoint.mdAndUp ? 'display-4 ': 'display-2'"
              >
                WIDERSTAND
              </v-col>
            </h1>
          </v-row>
          <v-row v-show="showContent">
            <v-col cols="12">
              <v-card
                class="alert-card"
                tile
                flat
              >
                <v-card-text class="white--text body-1 font-weight-light">
                  <v-row>
                    <v-col cols="12" class="font-weight-bold">
                      Wir sind Widerstand und Söhne.
                    </v-col>
                    <v-col cols="12">
                      Wir entwickeln individuelle, vernetzte <span class="font-weight-bold">Hardware- und Softwarelösungen</span> nach Maß.
                      Vom Konzept über den Prototypen bis zum fertigen Produkt.
                    </v-col>
                    <v-col>
                      <div class="divider"></div>
                    </v-col>
                    <v-col cols="12">
                      <v-img src="/img/wus_01.jpg"></v-img>
                    </v-col>
                    <v-col>
                      <div class="divider"></div>
                    </v-col>
                    <v-col cols="12">
                      Wir verbinden <span class="font-weight-bold">Technologie und Kreativität</span> mit Strom, Leidenschaft und Haltung.
                      Wir machen deine Konzernlobby zur Show, deine Bühne lebendig, deinen Laden zur Schatzkarte, deine Industrieanlage zum ruhigen Fluss und deine Plattform zu einer Achterbahn.
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-col>
                      <router-link to="/ueber-uns">
                        <v-btn
                          @click="setStep(1)"
                          dark
                          tile
                          class="elevation-0"
                          color="error"
                        >
                          Mehr erfahren
                          <v-icon small class="ml-3">
                            arrow_downward
                          </v-icon>
                        </v-btn>
                      </router-link>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense v-show="!showContent">
            <v-col>
              <v-icon color="white" class="mr-2">mouse</v-icon><v-icon color="white">360</v-icon>
            </v-col>
          </v-row>
          <v-row class="white--text" v-show="!showContent">
            <v-col>
              <v-icon color="white" class="mr-2">mouse</v-icon>x2 === ⬡
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'intro',
  data: () => ({
    showIntroTooltip: false
  }),
  methods: {
    ...mapMutations({
      setStep: 'SET_STEP'
    })
  },
  computed: {
    ...mapGetters([
      'showContent'
    ])
  }
}
</script>
