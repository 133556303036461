<template>
  <v-app
    :style="'background: linear-gradient(70deg, ' + computedGradientLeft + ' 0%, ' + computedGradientRight + ' 61%);}'"
  >
    <Three
      id="three"
      :step="step"
    />
      <v-btn
        x-large
        color="white"
        icon
        fixed
        right
        style="z-index: 100"
        class="mt-3 mr-12"
        v-if="!showNav && step !== 5 && step !== 6"
        @click="setShowContent(!showContent)"
      >
        <v-icon>
          {{showContent ? 'visibility' : 'visibility_off'}}
        </v-icon>
      </v-btn>
      <v-btn
        v-if="$vuetify.breakpoint.mdAndDown && !showNav && showContent"
        x-large
        color="white"
        icon
        fixed
        right
        style="z-index: 100"
        class="mt-3"
        @click="showNav = true"
      >
        <v-icon
          x-large
        >
          menu
        </v-icon>
      </v-btn>
    <v-navigation-drawer
      floating
      app
      v-model="showNav"
      right
      flat
      color="transparent"
    >
      <v-row
        dense
        class="text-right"
        v-if="$vuetify.breakpoint.mdAndDown"
      >
        <v-col>
          <v-btn
            icon
            x-large
            @click="showNav = false"
            class="ma-3 mr-4 elevation-0"
          >
            <v-icon x-large color="white">
              cancel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        class="text-center my-6"
      >
        <v-col>
          <img src="/wus_typo_w.svg" height="60px"/>
        </v-col>
      </v-row>
      <v-row>
       <v-col class="text-center">
         <v-btn
            x-large
            color="white"
            icon
            dark
            v-if="$vuetify.breakpoint.lgAndUp"
            @click="setShowContent(!showContent)"
            :disabled="step === 5 || step === 6"
          >
            <v-icon>
              {{showContent ? 'visibility' : 'visibility_off'}}
            </v-icon>
          </v-btn>
       </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col class="pt-0">
          <router-link to="/">
            <v-btn
              @click="setStep(0)"
              dark
              text
              tile
              :style="$route.name === 'intro' ? 'border-bottom:2px solid white' : '  '"
            >
              START
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col>
          <router-link to="/ueber-uns">
            <v-btn
              @click="setStep(1)"
              dark
              text
              tile
              :style="$route.name === 'about' ? 'border-bottom:2px solid white' : '  '"
            >
              ÜBER UNS
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col>
          <router-link to="/projekte">
            <v-btn
              @click="setStep(2)"
              dark
              text
              tile
              :style="$route.name === 'projects' ? 'border-bottom:2px solid white' : '  '"
            >
              PROJEKTE
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col>
          <router-link to="/kunden">
            <v-btn
              @click="setStep(3)"
              dark
              text
              tile
              :style="$route.name === 'customers' ? 'border-bottom:2px solid white' : '  '"
            >
              KUNDEN
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col>
          <router-link to="/kontakt">
            <v-btn
              @click="setStep(4)"
              dark
              text
              tile
              :style="$route.name === 'contact' ? 'border-bottom:2px solid white' : '  '"
            >
              KONTAKT
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col>
          <router-link to="/datenschutz">
            <v-btn
              @click="setStep(5)"
              dark
              text
              tile
              :style="$route.name === 'privacy' ? 'border-bottom:2px solid white' : '  '"
            >
              DATENSCHUTZ
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col>
          <router-link to="/impressum">
            <v-btn
              @click="setStep(6)"
              dark
              text
              tile
              :style="$route.name === 'imprint' ? 'border-bottom:2px solid white' : '  '"
            >
              IMPRESSUM
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-content
      app
      style="overflow-x: hidden !important"
    >
      <v-container
        fluid
        :class="$vuetify.breakpoint.smAndDown ? 'pa-0': ''"
      >
        <transition :name="reverse ? 'slide-fade-reverse' : 'slide-fade'">
          <router-view></router-view>
        </transition>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import Three from './components/Three'
import { Tween } from '@createjs/tweenjs'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'App',
  components: {
    Three
  },
  data: () => ({
    showNav: false,
    gradient: {
      left: { r: 3, g: 80, b: 94 },
      right: { r: 70, g: 50, b: 50 }
    },
    stepColors: [
      { left: { r: 3, g: 80, b: 94 }, right: { r: 70, g: 50, b: 50 } },
      { left: { r: 50, g: 180, b: 220 }, right: { r: 10, g: 10, b: 50 } },
      { left: { r: 60, g: 40, b: 30 }, right: { r: 80, g: 110, b: 80 } },
      { left: { r: 100, g: 80, b: 100 }, right: { r: 40, g: 20, b: 10 } },
      { left: { r: 255, g: 100, b: 100 }, right: { r: 205, g: 205, b: 215 } },
      { left: { r: 150, g: 150, b: 155 }, right: { r: 50, g: 50, b: 50 } },
      { left: { r: 100, g: 100, b: 105 }, right: { r: 30, g: 30, b: 30 } }
    ],
    bottomSheet: false
  }),
  mounted () {
    if (this.$vuetify.breakpoint.lgAndUp) {
      this.showNav = true
    }
    this.setStep(this.$route.meta.pos)
    document.addEventListener('touchend', (event) => {
      if (!this.showContent && !event.srcElement.classList.contains('v-icon') && !event.srcElement.classList.contains('v-btn__content')) {
        event.srcElement.click()
      }
    })
  },
  methods: {
    ...mapMutations({
      setShowContent: 'SET_SHOW_CONTENT',
      setStep: 'SET_STEP'
    }),
    tween (step) {
      Tween.get(this.gradient.left).to(step.left, 1000)
      Tween.get(this.gradient.right).to(step.right, 1000)
    }
  },

  watch: {
    step () {
      if (this.step !== undefined) {
        this.tween(this.stepColors[this.step])
        this.bottomSheet = false
      }
    },
    '$vuetify.breakpoint.mdAndUp' () {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.showNav = true
      }
    }
  },

  computed: {
    ...mapGetters([
      'reverse',
      'step',
      'showContent'
    ]),
    computedGradientLeft () {
      return 'rgba(' + this.gradient.left.r + ',' + this.gradient.left.g + ',' + this.gradient.left.b + ')'
    },
    computedGradientRight () {
      return 'rgba(' + this.gradient.right.r + ',' + this.gradient.right.g + ',' + this.gradient.right.b + ')'
    }
  }
}
</script>

<style>

#app {
  transition: all 1s ease-in-out;
  user-select: none;
}

.intro-tooltip {
  border-radius: 0px !important;
  background-color: rgba(0,0,0,0.6) !important;
  border: 1px solid #ff4444;
  border-left: 7px solid #ff4444 !important;
}

.tooltip-btn {
  vertical-align: top !important;
  margin-left: -30px !important;
  margin-top: -10px !important;
}

.tooltip-btn.small {
  margin-left: -15px !important;
}

.theme--light.v-tabs-items {
  background-color: rgba(156, 103, 103, 0) !important;
}

.alert-card {
  border-left: 8px solid #ff4444 !important;
  background-color: rgba(0,0,0,0.7) !important;
  backdrop-filter: blur(3px) !important;
  padding-left: 10px;
  padding-right: 10px;
}

.alert-card.about {
  background-color: rgba(0,0,0,0.5) !important;
}

.alert-card.customers {
  background-color: rgba(0,0,0,0.3) !important;
}

.alert-card.meta {
  background-color: rgba(0,0,0,0.2) !important;
}

.alert-card.projects {
  background-color: rgba(255,255,255,0.2) !important;
}

.alert-card.contact {
  border-left: 8px solid #ffffff !important;
  background-color: rgba(255,35,35,0.5) !important;
}

.v-slide-group__prev.v-slide-group__prev--disabled, .v-slide-group__next.v-slide-group__next--disabled {
  display: none !important;
}

a {
  text-decoration: none;
}

.divider {
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 2px dashed white;
  opacity: 0.4;
}

.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--open.v-navigation-drawer--right.theme--light {
  background-color: rgba(0,0,0,0.3) !important;
  backdrop-filter: blur(3px) !important;
  box-shadow: none;
}

.theme--dark.v-card.grid-card {
  background-color: rgba(0,0,0,0.001) !important;
  opacity: 0.9;
  backdrop-filter: blur(3px) !important;
}

.customer {
  filter: saturate(0.7);
}

.customer:hover {
  filter: none;
}

.slide-fade-enter-active {
  transition: all .7s .7s ease-out;
}
.slide-fade-leave-active {
  transition: all .7s ease-out;
}
.slide-fade-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
.slide-fade-enter {
  opacity: 0;
  transform: translateY(100%);
}
.slide-fade-enter-to {
  opacity: 1;
}

.slide-fade-reverse-enter-active {
  transition: all .7s .7s ease-out;
}
.slide-fade-reverse-leave-active {
  transition: all .7s ease-out;
}
.slide-fade-reverse-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
.slide-fade-reverse-enter {
  opacity: 0;
  transform: translateY(-100%);
}
.slide-fade-reverse-enter-to {
  opacity: 1;
}

</style>
