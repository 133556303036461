import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showContent: true,
    reverse: false,
    step: 0
  },
  mutations: {
    SET_SHOW_CONTENT (state, value) {
      state.showContent = value
    },
    SET_REVERSE (state, value) {
      state.reverse = value
    },
    SET_STEP (state, value) {
      state.step = value
    }
  },
  getters: {
    showContent (state) {
      return state.showContent
    },
    reverse (state) {
      return state.reverse
    },
    step (state) {
      return state.step
    }
  },
  actions: {
  },
  modules: {
  }
})
