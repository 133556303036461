<template>
  <div></div>
</template>

<script>
/* eslint-disable new-cap */
import { Vector3, Quaternion, Clock, Vector2, Raycaster, MeshPhongMaterial, SphereBufferGeometry, BoxBufferGeometry, Mesh, DirectionalLight, AmbientLight, Scene, WebGLRenderer, PerspectiveCamera, Object3D, WireframeGeometry, LineSegments, BufferGeometry, TextureLoader, Float32BufferAttribute, PointsMaterial, Points, Geometry, VertexColors, Color, AdditiveBlending } from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js'
import { ConvexObjectBreaker } from 'three/examples/jsm/misc/ConvexObjectBreaker.js'
import { OrbitControls } from '@/plugins/OrbitControls.js'
import * as ammo from 'ammo.js'
import { mapGetters } from 'vuex'

export default {
  name: 'Three',
  data: () => ({
    mouseX: undefined,
    mouseY: undefined,
    activeScene: undefined,
    scenes: [],
    camera: undefined,
    renderer: undefined,
    composer: undefined,
    effect1: undefined,
    effect2: undefined,
    imageWidth: 320,
    imageHeight: 320,
    imageData: undefined,
    particleSystem: undefined,
    gpuCompute: undefined,
    windowHalfX: window.innerWidth / 2,
    windowHalfY: window.innerHeight / 2,
    gravityConstant: 0,
    collisionConfiguration: undefined,
    dispatcher: undefined,
    broadphase: undefined,
    solver: undefined,
    physicsWorld: undefined,
    margin: 0.05,
    convexBreaker: undefined,
    // Rigid bodies include all movable objects
    rigidBodies: [],
    pos: undefined,
    quat: undefined,
    transformAux1: undefined,
    tempBtVec3_1: undefined,
    objectsToRemove: [],
    numObjectsToRemove: 0,
    impactPoint: undefined,
    impactNormal: undefined,
    Ammo: undefined,
    mouseCoords: undefined,
    raycaster: undefined,
    ballMaterial: undefined,
    clock: undefined,
    cloudObject: undefined,
    cloudObject2: undefined,
    mouseDown: 0
  }),
  mounted () {
    document.body.onmousedown = () => {
      ++this.mouseDown
    }
    document.body.onmouseup = () => {
      --this.mouseDown
    }
    this.initMouse()
    this.initThree()
    this.addEffect()
    this.addContent()
    this.addContent2()
    this.addContent3()
    this.addContent4()
    this.createPixelData()
    this.initAmmo()
    this.animate()
  },
  methods: {
    initAmmo () {
      this.convexBreaker = new ConvexObjectBreaker()
      this.pos = new Vector3()
      this.quat = new Quaternion()
      for (var i = 0; i < 500; i++) {
        this.objectsToRemove[i] = null
      }
      this.impactPoint = new Vector3()
      this.impactNormal = new Vector3()
      this.clock = new Clock()

      ammo().then((AmmoLib) => {
        this.Ammo = AmmoLib
        this.initPhysics()
        this.createObjects()
        this.initInput()
      })
    },
    initPhysics () {
      // Physics configuration
      this.collisionConfiguration = new this.Ammo.btDefaultCollisionConfiguration()
      this.dispatcher = new this.Ammo.btCollisionDispatcher(this.collisionConfiguration)
      this.broadphase = new this.Ammo.btDbvtBroadphase()
      this.solver = new this.Ammo.btSequentialImpulseConstraintSolver()
      this.physicsWorld = new this.Ammo.btDiscreteDynamicsWorld(this.dispatcher, this.broadphase, this.solver, this.collisionConfiguration)
      this.physicsWorld.setGravity(new this.Ammo.btVector3(0, -this.gravityConstant, 0))
      this.transformAux1 = new this.Ammo.btTransform()
      this.tempBtVec3_1 = new this.Ammo.btVector3(0, 0, 0)

      this.mouseCoords = new Vector2()
      this.raycaster = new Raycaster()
      this.ballMaterial = new MeshPhongMaterial({ color: 0xffffff, flatShading: true })
    },
    createObject (mass, halfExtents, pos, quat, material) {
      var object = new Mesh(new SphereBufferGeometry(halfExtents.x * 2, halfExtents.y * 2, halfExtents.z * 2), material)
      object.position.copy(pos)
      object.quaternion.copy(quat)
      this.convexBreaker.prepareBreakableObject(object, mass, new Vector3(), new Vector3(), true)
      this.createDebrisFromBreakableObject(object)
    },
    createObjects () {
      // Stones
      var stoneMass = 300
      var stoneHalfExtents = new Vector3(1, 1, 1)
      var numStones = 16
      for (var i = 0; i < numStones; i++) {
        this.pos.set(this.randomG() * 30 - 10, this.randomG() * 30 - 10, this.randomG() * 40 - 20)
        this.quat.set(Math.random() * 1.3 - 0.7, Math.random() * 1.3 - 0.7, Math.random() * 1.3 - 0.7, Math.random() * 1.3 - 0.7)
        this.createObject(stoneMass, stoneHalfExtents, this.pos, this.quat, this.createMaterial(0xff4444))
      }
    },
    createParalellepipedWithPhysics (sx, sy, sz, mass, pos, quat, material) {
      var object = new Mesh(new BoxBufferGeometry(sx, sy, sz, 1, 1, 1), material)
      var shape = new this.Ammo.btBoxShape(new this.Ammo.btVector3(sx * 0.5, sy * 0.5, sz * 0.5))
      shape.setMargin(this.margin)

      this.createRigidBody(object, shape, mass, pos, quat)

      return object
    },
    createDebrisFromBreakableObject (object) {
      object.castShadow = true
      object.receiveShadow = true
      var shape = this.createConvexHullPhysicsShape(object.geometry.attributes.position.array)
      shape.setMargin(this.margin)
      var body = this.createRigidBody(object, shape, object.userData.mass, null, null, object.userData.velocity, object.userData.angularVelocity)
      // Set pointer back to the three object only in the debris objects
      var btVecUserData = new this.Ammo.btVector3(0, 0, 0)
      btVecUserData.threeObject = object
      body.setUserPointer(btVecUserData)
    },
    removeDebris (object) {
      this.scenes[0].remove(object)
      this.physicsWorld.removeRigidBody(object.userData.physicsBody)
    },
    createConvexHullPhysicsShape (coords) {
      var shape = new this.Ammo.btConvexHullShape()
      for (var i = 0, il = coords.length; i < il; i += 3) {
        this.tempBtVec3_1.setValue(coords[i], coords[i + 1], coords[i + 2])
        var lastOne = (i >= (il - 3))
        shape.addPoint(this.tempBtVec3_1, lastOne)
      }
      return shape
    },
    createRigidBody (object, physicsShape, mass, pos, quat, vel, angVel) {
      if (pos) {
        object.position.copy(pos)
      } else {
        pos = object.position
      }
      if (quat) {
        object.quaternion.copy(quat)
      } else {
        quat = object.quaternion
      }
      var transform = new this.Ammo.btTransform()
      transform.setIdentity()
      transform.setOrigin(new this.Ammo.btVector3(pos.x, pos.y, pos.z))
      transform.setRotation(new this.Ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w))
      var motionState = new this.Ammo.btDefaultMotionState(transform)
      var localInertia = new this.Ammo.btVector3(0, 0, 0)
      physicsShape.calculateLocalInertia(mass, localInertia)
      var rbInfo = new this.Ammo.btRigidBodyConstructionInfo(mass, motionState, physicsShape, localInertia)
      var body = new this.Ammo.btRigidBody(rbInfo)
      body.setFriction(0.5)
      if (vel) {
        body.setLinearVelocity(new this.Ammo.btVector3(vel.x, vel.y, vel.z))
      }
      if (angVel) {
        body.setAngularVelocity(new this.Ammo.btVector3(angVel.x, angVel.y, angVel.z))
      }
      object.userData.physicsBody = body
      object.userData.collided = false
      this.scenes[0].add(object)
      if (mass > 0) {
        this.rigidBodies.push(object)
        // Disable deactivation
        body.setActivationState(4)
      }
      this.physicsWorld.addRigidBody(body)
      return body
    },
    createRandomColor () {
      return Math.floor(Math.random() * (1 << 24))
    },
    createMaterial (color) {
      color = color || this.createRandomColor()
      return new MeshPhongMaterial({ color: color, flatShading: true })
    },
    initInput () {
      var timeout
      var lastTap = 0
      document.addEventListener('touchend', (event) => {
        var currentTime = new Date().getTime()
        var tapLength = currentTime - lastTap
        clearTimeout(timeout)
        if (tapLength < 500 && tapLength > 0) {
          this.mouseCoords.set(
            (event.changedTouches[0].clientX / window.innerWidth) * 2 - 1,
            -(event.changedTouches[0].clientY / window.innerHeight) * 2 + 1
          )
          if (!this.showContent && this.step === 0) {
            this.fireImpulse()
          }
          event.preventDefault()
        } else {
          timeout = setTimeout(() => {
            clearTimeout(timeout)
          }, 500)
        }
        lastTap = currentTime
      })
      document.addEventListener('dblclick', (event) => {
        this.mouseCoords.set(
          (event.clientX / window.innerWidth) * 2 - 1,
          -(event.clientY / window.innerHeight) * 2 + 1
        )
        if (!this.showContent && this.step === 0) {
          this.fireImpulse()
        }
      }, false)
    },
    fireImpulse () {
      this.raycaster.setFromCamera(this.mouseCoords, this.camera)
      // Creates a ball and throws it
      var ballMass = Math.random() * 30 + 15
      var ballRadius = Math.random()

      var ball = new Mesh(new SphereBufferGeometry(ballRadius, 1, 1), this.ballMaterial)
      ball.castShadow = true
      ball.receiveShadow = false
      var ballShape = new this.Ammo.btSphereShape(ballRadius)
      ballShape.setMargin(this.margin)
      this.pos.copy(this.raycaster.ray.direction)
      this.pos.add(this.raycaster.ray.origin)
      this.quat.set(Math.random(), Math.random(), Math.random(), 1)
      var ballBody = this.createRigidBody(ball, ballShape, ballMass, this.pos, this.quat)

      this.pos.copy(this.raycaster.ray.direction)
      this.pos.multiplyScalar(24)
      ballBody.setLinearVelocity(new this.Ammo.btVector3(this.pos.x, this.pos.y, this.pos.z))
    },
    updatePhysics (deltaTime) {
      // Step world
      this.physicsWorld.stepSimulation(deltaTime, 10)

      // Update rigid bodies
      for (var i = 0, il = this.rigidBodies.length; i < il; i++) {
        var objThree = this.rigidBodies[i]
        var objPhys = objThree.userData.physicsBody
        var ms = objPhys.getMotionState()

        if (ms) {
          ms.getWorldTransform(this.transformAux1)
          var p = this.transformAux1.getOrigin()
          var q = this.transformAux1.getRotation()
          objThree.position.set(p.x(), p.y(), p.z())
          objThree.quaternion.set(q.x(), q.y(), q.z(), q.w())

          objThree.userData.collided = false
        }
      }

      // eslint-disable-next-line no-redeclare
      for (var i = 0, il = this.dispatcher.getNumManifolds(); i < il; i++) {
        var contactManifold = this.dispatcher.getManifoldByIndexInternal(i)
        var rb0 = this.Ammo.castObject(contactManifold.getBody0(), this.Ammo.btRigidBody)
        var rb1 = this.Ammo.castObject(contactManifold.getBody1(), this.Ammo.btRigidBody)

        var threeObject0 = this.Ammo.castObject(rb0.getUserPointer(), this.Ammo.btVector3).threeObject
        var threeObject1 = this.Ammo.castObject(rb1.getUserPointer(), this.Ammo.btVector3).threeObject

        if (!threeObject0 && !threeObject1) {
          continue
        }

        var userData0 = threeObject0 ? threeObject0.userData : null
        var userData1 = threeObject1 ? threeObject1.userData : null

        var breakable0 = userData0 ? userData0.breakable : false
        var breakable1 = userData1 ? userData1.breakable : false

        var collided0 = userData0 ? userData0.collided : false
        var collided1 = userData1 ? userData1.collided : false

        if ((!breakable0 && !breakable1) || (collided0 && collided1)) {
          continue
        }

        var contact = false
        var maxImpulse = 0
        for (var j = 0, jl = contactManifold.getNumContacts(); j < jl; j++) {
          var contactPoint = contactManifold.getContactPoint(j)

          if (contactPoint.getDistance() < 0) {
            contact = true
            var impulse = contactPoint.getAppliedImpulse()

            if (impulse > maxImpulse) {
              maxImpulse = impulse
              var pos = contactPoint.get_m_positionWorldOnB()
              var normal = contactPoint.get_m_normalWorldOnB()
              this.impactPoint.set(pos.x(), pos.y(), pos.z())
              this.impactNormal.set(normal.x(), normal.y(), normal.z())
            }

            break
          }
        }

        // If no point has contact, abort
        if (!contact) continue

        // Subdivision

        var fractureImpulse = 0.01

        if (breakable0 && !collided0 && maxImpulse > fractureImpulse) {
          var debris = this.convexBreaker.subdivideByImpact(threeObject0, this.impactPoint, this.impactNormal, 1, 2, 1.5)

          var numObjects = debris.length
          // eslint-disable-next-line no-redeclare
          for (var j = 0; j < numObjects; j++) {
            var vel = rb0.getLinearVelocity()
            var angVel = rb0.getAngularVelocity()
            var fragment = debris[j]
            fragment.userData.velocity.set(vel.x(), vel.y(), vel.z())
            fragment.userData.angularVelocity.set(angVel.x(), angVel.y(), angVel.z())

            this.createDebrisFromBreakableObject(fragment)
          }

          this.objectsToRemove[this.numObjectsToRemove++] = threeObject0
          userData0.collided = true
        }

        if (breakable1 && !collided1 && maxImpulse > fractureImpulse) {
          // eslint-disable-next-line no-redeclare
          var debris = this.convexBreaker.subdivideByImpact(threeObject1, this.impactPoint, this.impactNormal, 1, 2, 1.5)

          // eslint-disable-next-line no-redeclare
          var numObjects = debris.length
          // eslint-disable-next-line no-redeclare
          for (var j = 0; j < numObjects; j++) {
            // eslint-disable-next-line no-redeclare
            var vel = rb1.getLinearVelocity()
            // eslint-disable-next-line no-redeclare
            var angVel = rb1.getAngularVelocity()
            // eslint-disable-next-line no-redeclare
            var fragment = debris[j]
            fragment.userData.velocity.set(vel.x(), vel.y(), vel.z())
            fragment.userData.angularVelocity.set(angVel.x(), angVel.y(), angVel.z())

            this.createDebrisFromBreakableObject(fragment)
          }

          this.objectsToRemove[this.numObjectsToRemove++] = threeObject1
          userData1.collided = true
        }
      }

      // eslint-disable-next-line no-redeclare
      for (var i = 0; i < this.numObjectsToRemove; i++) {
        this.removeDebris(this.objectsToRemove[i])
      }
      this.numObjectsToRemove = 0
    },
    initMouse () {
      document.onmousemove = (e) => {
        this.mouseX = e.clientX - window.innerWidth / 2
        this.mouseY = e.clientY - window.innerHeight / 2
      }
    },
    initThree () {
      if (this.scenes.length === 0) {
        const light = new DirectionalLight(0xffffff)
        const ambiLight = new AmbientLight(0x444444)
        light.position.set(-50, 1, 1)
        for (let i = 0; i <= 6; i++) {
          this.scenes[i] = new Scene()
          this.scenes[i].add(ambiLight.clone())
          this.scenes[i].add(light.clone())
          this.scenes[i].position.y = i * -45
          if (i === 1) {
            this.scenes[i].scale.x = 0.3
            this.scenes[i].scale.y = 0.3
            this.scenes[i].scale.z = 0.3
          }
        }
        this.activeScene = this.scenes[0]
      }
      if (!this.camera) {
        this.addPerspectiveCamera()
        // this.addOrthographicCamera()
        this.camera.position.z = 40
      }
      if (!this.renderer) {
        this.renderer = new WebGLRenderer({ alpha: true, antialias: true })
        this.renderer.setSize(
          window.innerWidth,
          window.innerHeight
        )
        document.querySelector('#three').appendChild(
          this.renderer.domElement
        )
        window.addEventListener('resize', this.onWindowResize, false)
        document.addEventListener('touchstart', this.onDocumentTouchStart, false)
        document.addEventListener('touchend', this.onDocumentTouchEnd, false)
        document.addEventListener('touchmove', this.onDocumentTouchMove, false)
      }
      if (!this.orbitControls) {
        this.createOrbitControls(document.querySelector('#three'))
      }
    },
    randomG () {
      var r = 0
      for (var i = 2; i > 0; i--) {
        r += Math.random()
      }
      return r / 2
    },
    addPerspectiveCamera () {
      this.camera = new PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.2, 2000)
    },
    addContent () {
      this.cloudObject = new Object3D()
      this.scenes[1].add(this.cloudObject)
      for (var i = 0; i < 50; i++) {
        const geometry = new SphereBufferGeometry(1, Math.round(Math.random() * 10) + 5, Math.round(Math.random() * 10) + 5)
        const material = new WireframeGeometry(geometry)
        var line = new LineSegments(material)
        line.material.depthTest = false
        line.material.opacity = 0.05
        line.material.transparent = true
        // var mesh = new Mesh(geometry, material)
        const tmpLine = line.clone()
        tmpLine.position.set(this.randomG() * 200 - 100, this.randomG() * 200 - 20, this.randomG() * 200 - 100)
        tmpLine.rotation.set(Math.random() * 2, Math.random() * 2, Math.random() * 2)
        const tmpScale = Math.random() * 30 + 1
        tmpLine.scale.x = tmpScale
        tmpLine.scale.y = tmpScale
        tmpLine.scale.z = tmpScale
        this.cloudObject.add(tmpLine)
      }
    },
    addContent2 () {
      var geometry = new BufferGeometry()
      var vertices = []
      const materials = []

      var textureLoader = new TextureLoader()

      var sprite1 = textureLoader.load('/ellipse.svg')

      for (var i = 0; i < 50; i++) {
        var x = Math.random() * 200 - 100
        var y = Math.random() * 200 - 100
        var z = Math.random() * 200 - 100

        vertices.push(x, y, z)
      }

      geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3))
      geometry.dynamic = true

      const parameters = [
        [[1.0, 0.2, 0.5], sprite1, 3],
        [[0.95, 0.1, 0.5], sprite1, 13],
        [[0.90, 0.05, 0.5], sprite1, 10],
        [[0.85, 0, 0.5], sprite1, 8],
        [[0.80, 0, 0.5], sprite1, 5]
      ]

      for (let i = 0; i < parameters.length; i++) {
        var color = parameters[i][0]
        var sprite = parameters[i][1]
        var size = parameters[i][2]

        materials[i] = new PointsMaterial({ size: size, map: sprite, blending: AdditiveBlending, depthTest: false, transparent: true, opacity: 0.1 })
        materials[i].color.setHSL(color[0], color[1], color[2])

        var particles = new Points(geometry, materials[i])

        this.scenes[2].add(particles)
      }
    },
    addContent3 () {
      var geometry = new BufferGeometry()
      var vertices = []
      const materials = []

      var textureLoader = new TextureLoader()

      var sprite1 = textureLoader.load('/rect.svg')

      for (var i = 0; i < 50; i++) {
        var x = Math.random() * 200 - 100
        var y = Math.random() * 200 - 100
        var z = Math.random() * 200 - 100

        vertices.push(x, y, z)
      }

      geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3))
      geometry.dynamic = true

      const parameters = [
        [[1.0, 0.2, 0.5], sprite1, 3],
        [[0.95, 0.1, 0.5], sprite1, 13],
        [[0.90, 0.05, 0.5], sprite1, 10],
        [[0.85, 0, 0.5], sprite1, 8],
        [[0.80, 0, 0.5], sprite1, 5]
      ]

      for (let i = 0; i < parameters.length; i++) {
        var color = parameters[i][0]
        var sprite = parameters[i][1]
        var size = parameters[i][2]

        materials[i] = new PointsMaterial({ size: size, map: sprite, blending: AdditiveBlending, depthTest: false, transparent: true, opacity: 0.1 })
        materials[i].color.setHSL(color[0], color[1], color[2])

        var particles = new Points(geometry, materials[i])

        this.scenes[3].add(particles)
      }
    },
    addContent4 () {
      this.cloudObject2 = new Object3D()
      this.scenes[4].add(this.cloudObject2)
      const material = new MeshPhongMaterial({ color: 0xffffff, flatShading: true })
      for (let i = 0; i < 50; i++) {
        const size = Math.random() * 2 + 1
        var object = new Mesh(new BoxBufferGeometry(size, size, size), material)
        object.rotation.set(Math.random() * 1.3 - 0.7, Math.random() * 1.3 - 0.7, Math.random() * 1.3 - 0.7)
        object.position.set(this.randomG() * 50 - 15, this.randomG() * 50 - 25, this.randomG() * 50 - 25)
        this.cloudObject2.add(object)
      }
    },
    addEffect () {
      this.composer = new EffectComposer(this.renderer)
      this.composer.addPass(new RenderPass(this.scenes[4], this.camera))

      const glitchPass = new GlitchPass()
      glitchPass.goWild = true
      this.composer.addPass(glitchPass)
    },
    animate () {
      requestAnimationFrame(this.animate)
      this.orbitControls.update()
      if (this.step === 1 && this.cloudObject) {
        for (const child of this.cloudObject.children) {
          child.rotateY(0.005)
        }
      }
      if (this.step === 4 && this.cloudObject2) {
        for (const child of this.cloudObject2.children) {
          child.rotateY(0.005)
        }
      }
      let time
      let object
      let i
      if (this.step === 2) {
        time = Date.now() * 0.000005
        for (i = 0; i < this.scenes[this.step].children.length; i++) {
          object = this.scenes[this.step].children[i]
          if (object instanceof Points) {
            object.rotation.y = time * (i < 4 ? i + 1 : -(i + 1))
            for (let j = 1; j < object.geometry.attributes.position.array.length; j += 3) {
              if (object.geometry.attributes.position.array[j] < 200) {
                object.geometry.attributes.position.array[j] += 0.01 + (i / 100)
              } else {
                object.geometry.attributes.position.array[j] = Math.random() * 100 - 200
                object.geometry.attributes.position.array[j - 1] = Math.random() * 200 - 100
              }
            }
            object.geometry.attributes.position.needsUpdate = true
          }
        }
      }
      if (this.step === 3) {
        time = Date.now() * 0.000005
        for (i = 0; i < this.scenes[this.step].children.length; i++) {
          object = this.scenes[this.step].children[i]
          if (object instanceof Points) {
            object.rotation.y = time * (i < 4 ? i + 1 : -(i + 1))
            for (let j = 1; j < object.geometry.attributes.position.array.length; j += 3) {
              if (object.geometry.attributes.position.array[j] < 200) {
                object.geometry.attributes.position.array[j] += 0.01 + (i / 100)
              } else {
                object.geometry.attributes.position.array[j] = Math.random() * 100 - 200
                object.geometry.attributes.position.array[j - 1] = Math.random() * 200 - 100
              }
            }
            object.geometry.attributes.position.needsUpdate = true
          }
        }
      }
      this.render()
    },
    // Image to particle
    createPixelData () {
      var image = document.createElement('img')
      var canvas = document.createElement('canvas')
      var context = canvas.getContext('2d')

      image.crossOrigin = 'Anonymous'
      image.onload = () => {
        image.width = canvas.width = this.imageWidth
        image.height = canvas.height = this.imageHeight

        context.fillStyle = context.createPattern(image, 'no-repeat')
        context.fillRect(0, 0, this.imageWidth, this.imageHeight)
        this.imageData = context.getImageData(0, 0, this.imageWidth, this.imageHeight).data

        this.createParticles()
      }
      // image.src = '/wus_logo_320.png'
      image.src = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/175711/tree_star.jpg'
      // image.src = 'https://images.unsplash.com/photo-1515879218367-8466d910aaa4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80'
    },
    // Image to particle
    createParticles () {
      // var colors = []
      var weights = [0.2126, 0.7152, 0.0722]
      var c = 0

      var geometry
      //  var material
      var x, y
      var zRange = 200

      geometry = new Geometry()
      geometry.dynamic = false

      x = this.imageWidth * -0.25
      y = this.imageHeight * 0.25

      var pm = new PointsMaterial({ size: 0.1, vertexColors: VertexColors })

      for (var i = 0; i < this.imageHeight; i++) {
        for (var j = 0; j < this.imageWidth; j++) {
          var color = new Color()

          color.setRGB(this.imageData[c] / 255, this.imageData[c + 1] / 255, this.imageData[c + 2] / 255)

          var weight = color.r * weights[0] + color.g * weights[1] + color.b * weights[2]

          var vertex = new Vector3()

          vertex.x = x
          vertex.y = y
          vertex.z = (zRange * -0.5) + (zRange * weight)
          // vertex.z = Math.random() * 50 - 25

          if (color.r > 0.1 && color.g > 0.1 && color.b > 0.1) {
            geometry.vertices.push(vertex)
            geometry.colors.push(color)
          }

          c += 4
          x++
        }

        x = this.imageWidth * -0.5
        y--
      }
      geometry.scale(0.5, 0.5, 0.5)
      this.particleSystem = new Points(geometry, pm)
      this.particleSystem.position.x = 10
      this.scenes[0].add(this.particleSystem)
    },
    // eslint-disable-next-line vue/require-render-return
    render () {
      if (this.Ammo) {
        var deltaTime = this.clock.getDelta()
        this.updatePhysics(deltaTime)
      }
      if (this.step === 4 && !this.showContent && this.mouseDown) {
        this.composer.render(this.activeScene, this.camera)
      } else {
        this.renderer.render(this.activeScene, this.camera)
      }
    },
    // Complex particles
    onWindowResize () {
      this.windowHalfX = window.innerWidth / 2
      this.windowHalfY = window.innerHeight / 2

      this.camera.aspect = window.innerWidth / window.innerHeight
      this.camera.updateProjectionMatrix()

      this.renderer.setSize(window.innerWidth, window.innerHeight)
    },
    onDocumentTouchStart (event) {
      ++this.mouseDown
      if (event.touches.length === 1) {
        // event.preventDefault()
        this.mouseX = event.touches[0].pageX - this.windowHalfX
        this.mouseY = event.touches[0].pageY - this.windowHalfY
      }
    },
    onDocumentTouchEnd (event) {
      --this.mouseDown
    },
    onDocumentTouchMove (event) {
      if (event.touches.length === 1) {
        // event.preventDefault()
        this.mouseX = event.touches[0].pageX - this.windowHalfX
        this.mouseY = event.touches[0].pageY - this.windowHalfY
      }
    },
    createOrbitControls (domElement) {
      if (this.orbitControls) {
        this.orbitControls.dispose()
      }
      this.orbitControls = new OrbitControls(this.camera, domElement)
      this.orbitControls.minPolarAngle = Math.PI / 2
      this.orbitControls.maxPolarAngle = Math.PI / 2
      this.orbitControls.enableDamping = true
      this.orbitControls.target.set(0, 0, 0)
      this.orbitControls.enablePan = false
      this.orbitControls.enableZoom = false
      this.orbitControls.screenSpacePanning = true
      this.orbitControls.update()
    }
  },
  computed: {
    ...mapGetters([
      'showContent',
      'step'
    ])
  },
  watch: {
    step (newValue, oldValue) {
      this.orbitControls.pan(0, (oldValue - newValue) * window.innerHeight)
      this.activeScene = this.scenes[this.step]
    },
    showContent () {
      if (this.showContent) {
        this.orbitControls.dispose()
        this.orbitControls.changeDomElement(document.querySelector('#three'))
      } else {
        this.orbitControls.dispose()
        this.orbitControls.changeDomElement(document.querySelector('.v-content'))
      }
    }
  }
}
</script>

<style>
  #three {
    position: fixed;
    height: 100vh;
    width: 100vw;
  }
</style>
