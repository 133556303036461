<template>
  <div>
    <v-container class="fill-height">
      <v-row>
        <v-col cols="12" md="10">
          <v-row class="mt-12">
            <h1>
              <v-col
                cols="12"
                class="font-weight-bold white--text"
                :class="$vuetify.breakpoint.mdAndUp ? 'display-1 ': 'headline'"
              >
                IMPRESSUM
              </v-col>
            </h1>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card
                class="alert-card meta"
                tile
                flat
              >
                <v-card-text class="white--text body-1 py-12 font-weight-light">
                  <v-row>
                      <v-col cols="12">
                       <span class="font-weight-bold">Widerstand und Söhne GmbH</span><br>
                        Prittwitzstr. 100<br>
                        89075 Ulm
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col>
                        <span class="font-weight-bold">Registergericht:</span> Amtsgericht Ulm<br>
                        <span class="font-weight-bold">Handelsregisternummer:</span> HRB 734906<br>
                        <span class="font-weight-bold">Umsatzsteuer-ID:</span> DE311314354
                      </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                    <span class="font-weight-bold">Geschäftsführer:</span><br>
                    Dirk Bayer<br>
                    Falko Pross<br>
                    Manuel Schall
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                        <span class="font-weight-bold">Verantwortlich für den Inhalt:</span><br>
                        Dirk Bayer<br>
                        Falko Pross<br>
                        Manuel Schall
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <span class="font-weight-bold">Kontakt:</span><br>
                      info@widerstand.digital<br>
                      +49 160 95 144 808
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import { mapMutations } from 'vuex'

export default {
  name: 'imprint',
  mounted () {
    this.setShowContent(true)
  },
  methods: {
    ...mapMutations({
      setShowContent: 'SET_SHOW_CONTENT'
    })
  }
}
</script>
