<template>
  <div>
    <v-container class="fill-height">
      <v-row>
        <v-col cols="12" md="10">
          <v-row class="mt-12">
            <h1>
              <v-col
                cols="12"
                class="font-weight-bold white--text"
                :class="$vuetify.breakpoint.mdAndUp ? 'display-1 ': 'headline'"
              >
                ÜBER UNS
              </v-col>
            </h1>
          </v-row>
          <v-row v-show="showContent">
            <v-col>
              <v-card
                class="alert-card about"
                tile
                flat
              >
              <v-card-text>
                  <v-tabs
                    dark
                    background-color="transparent"
                    v-model="aboutTab"
                    grow
                  >
                    <v-tab>
                      WER WIR SIND
                    </v-tab>
                    <v-tab>
                      KERNBEREICHE
                    </v-tab>
                    <v-tab>
                      WERKZEUGE
                    </v-tab>
                    <v-tab>
                      ERGEBNISSE
                    </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="aboutTab">
                    <v-tab-item>
                      <v-card
                        flat
                        class="mt-6 body-1 font-weight-light"
                        dark
                        color="transparent"
                      >
                        <v-row>
                          <v-col cols="12">
                            Wir sind ein <span class="font-weight-bold">interdisziplinäres Team</span> aus Experten unterschiedlicher IT-Richtungen: Automatisierung, Software-Entwicklung und UI-Design treffen bei uns auf moderne Web-Technologien und genügend Spieltrieb um über die Grenzen des Üblichen hinauszuwachsen.
                          </v-col>
                          <v-col cols="12">
                            Damit decken wir eine <span class="font-weight-bold">Bandbreite</span> ab, die in Kombination mit unserer Teamgröße auch überregional einzigartig ist: Wir bieten Full-Stack-Entwicklung bei kurzen Laufwegen, voller Flexibilität und schneller Kommunikation.
                          </v-col>
                          <v-col>
                            <div class="divider"></div>
                          </v-col>
                          <v-col cols="12">
                            <v-img src="/img/wus_02.jpg"></v-img>
                          </v-col>
                          <v-col>
                            <div class="divider"></div>
                          </v-col>
                          <v-col cols="12">
                            Wir sehen den Entwicklungsprozess als <span class="font-weight-bold">großes  Ganzes</span>. Wir arbeiten im Dienste eines übergeordneten Konzepts und verfolgen stets das Ziel, Lösungen zu schaffen, die zu einhundert Prozent passen und so nachhaltig wie möglich sind. Wir arbeiten nicht nur ab, sondern denken - falls gewünscht - immer mit und suchen gemeinsam den besten Weg.
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card
                        dark
                        color="transparent"
                        class="mt-6 body-1 font-weight-light"
                        flat
                      >
                        <v-row>
                          <v-col cols="12">
                            Hier fühlen wir uns zu Hause - haben aber auch keine Angst über den Tellerrand zu blicken und uns in neue <span class="font-weight-bold">Bereiche</span> zu wagen:
                          </v-col>
                          <v-col>
                            <div class="divider"></div>
                          </v-col>
                          <v-col cols="12">
                            <v-img src="/img/wus_03.jpg"></v-img>
                          </v-col>
                          <v-col>
                            <div class="divider"></div>
                          </v-col>
                          <v-col cols="12">
                            <v-row>
                              <v-col v-for="skill in skills" :key="skill.text" cols="6" sm="4" md="3" class="text-center">
                                <v-card
                                  flat
                                  tile
                                  class="fill-height grid-card"
                                >
                                  <v-container class="fill-height">
                                    <v-row align="center">
                                      <v-col cols="12" class="text-uppercase caption" v-html="skill.text"></v-col>
                                      <v-col cols="12" class="pt-1 text-uppercase caption">
                                        <v-icon large>
                                          {{skill.icon}}
                                        </v-icon>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card
                        dark
                        color="transparent"
                        class="mt-6 body-1 font-weight-light"
                        flat
                      >
                        <v-row>
                          <v-col cols="12">
                            Neben unseren Entwicklungs-Arbeitsplätzen verfügen wir über ein bestens ausgestattetes Prototyping- und Test-Labor:
                          </v-col>
                          <v-col>
                            <div class="divider"></div>
                          </v-col>
                          <v-col cols="12">
                            <v-img src="/img/wus_04.jpg"></v-img>
                          </v-col>
                          <v-col>
                            <div class="divider"></div>
                          </v-col>
                          <v-col cols="12">
                            <v-row>
                              <v-col v-for="tool in tools" :key="tool.text" cols="6" sm="4" md="3" class="text-center">
                                <v-card
                                  flat
                                  tile
                                  class="fill-height grid-card"
                                >
                                  <v-container class="fill-height">
                                    <v-row align="center">
                                      <v-col class="text-uppercase caption" v-html="tool.text"></v-col>
                                      <v-col cols="12" class="pt-1 text-uppercase caption">
                                        <v-icon large>
                                          {{tool.icon}}
                                        </v-icon>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card
                        dark
                        color="transparent"
                        class="mt-6 body-1 font-weight-light"
                        flat
                      >
                        <v-row>
                          <v-col cols="12">
                            So vielfältig unsere Aufgaben und Kompetenzen sind, so vielfältig sind auch die <span class="font-weight-bold">Arbeitsergebnisse</span> unserer Projekte, die je nach Aufgabe natürlich sehr unterschiedlich ausfallen können und sollen.
                          </v-col>
                          <v-col>
                            <div class="divider"></div>
                          </v-col>
                          <v-col cols="12">
                            <v-row>
                              <v-col v-for="result in results" :key="result.text" cols="6" sm="4" md="3" class="text-center">
                                <v-card
                                  flat
                                  tile
                                  class="fill-height grid-card"
                                >
                                  <v-container class="fill-height">
                                    <v-row align="center">
                                      <v-col class="text-uppercase caption" v-html="result.text"></v-col>
                                      <v-col cols="12" class="pt-1 text-uppercase caption">
                                        <v-icon large>
                                          {{result.icon}}
                                        </v-icon>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                  <v-row class="mt-4">
                    <v-col>
                      <router-link to="/projekte">
                        <v-btn
                          @click="setStep(2)"
                          dark
                          tile
                          color="error"
                          class="elevation-0"
                        >
                          Projekte
                          <v-icon small class="ml-3">
                            arrow_downward
                          </v-icon>
                        </v-btn>
                      </router-link>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense v-show="!showContent">
            <v-col>
              <v-icon color="white" class="mr-2">mouse</v-icon><v-icon color="white">360</v-icon>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'about',
  data: () => ({
    aboutTab: 0,
    skills: [
      { text: 'Konzeption', icon: 'content_paste' },
      { text: 'Hardware-Entwicklung', icon: 'construction' },
      { text: 'Machbarkeits<wbr>studien', icon: 'pest_control_rodent' },
      { text: 'Prototyping', icon: 'architecture' },
      { text: 'Auto<wbr>matisierung', icon: 'account_tree' },
      { text: 'Software-Entwicklung', icon: 'personal_video' },
      { text: 'Web-Entwicklung', icon: 'laptop' },
      { text: 'App-Entwicklung', icon: 'smartphone' },
      { text: 'Internet of things', icon: 'settings_remote' },
      { text: 'Interaktive Exponate', icon: 'emoji_people' },
      { text: 'Virtual / Augmented Reality', icon: '3d_rotation' },
      { text: 'Usability Engineering', icon: 'touch_app' }
    ],
    tools: [
      { text: 'Sensorik / Aktorik', icon: 'settings_input_antenna' },
      { text: 'Elektronik', icon: 'power' },
      { text: 'Micro-Controller', icon: 'memory' },
      { text: 'Linux', icon: 'article' },
      { text: 'Docker', icon: 'anchor' },
      { text: 'C / C++', icon: 'code' },
      { text: 'Simatic Step7 / WinCC (TIA Portal)', icon: 'engineering' },
      { text: '.NET / C# / WPF', icon: 'code' },
      { text: 'Unity', icon: '3d_rotation' },
      { text: 'OpenCv', icon: 'image_search' },
      { text: 'Node.js / Sockets', icon: 'compare_arrows' },
      { text: 'JavaScript / TypeScript', icon: 'code' },
      { text: 'HTML / CSS', icon: 'web' },
      { text: 'SQL / NoSQL', icon: 'storage' },
      { text: 'Reaktive Frameworks', icon: 'autorenew' },
      { text: 'Adobe Creative Suite', icon: 'image' }
    ],
    results: [
      { text: 'Konzepte', icon: 'assignment_turned_in' },
      { text: 'Schaltpläne', icon: 'mediation' },
      { text: 'Hardware-Prototypen', icon: 'developer_mode' },
      { text: 'Vernetzte Systeme', icon: 'language' },
      { text: 'Server', icon: 'dns' },
      { text: 'Anwendungen', icon: 'dvr' },
      { text: 'Web-Anwendungen', icon: 'phonelink' },
      { text: 'Apps', icon: 'smartphone' },
      { text: 'Interaktive Exponate', icon: 'explore' }
    ]
  }),
  methods: {
    ...mapMutations({
      setStep: 'SET_STEP'
    })
  },
  computed: {
    ...mapGetters([
      'showContent'
    ])
  }
}
</script>
