import Vue from 'vue'
import VueRouter from 'vue-router'
import Intro from '../views/Intro.vue'
import About from '../views/About.vue'
import Projects from '../views/Projects.vue'
import Customers from '../views/Customers.vue'
import Contact from '../views/Contact.vue'
import Privacy from '../views/Privacy.vue'
import Imprint from '../views/Imprint.vue'
import Store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'intro',
    component: Intro,
    meta: { pos: 0 }
  },
  {
    path: '/ueber-uns',
    name: 'about',
    component: About,
    meta: { pos: 1 }
  },
  {
    path: '/projekte/:project?',
    name: 'projects',
    component: Projects,
    meta: { pos: 2 }
  },
  {
    path: '/kunden',
    name: 'customers',
    component: Customers,
    meta: { pos: 3 }
  },
  {
    path: '/kontakt',
    name: 'contact',
    component: Contact,
    meta: { pos: 4 }
  },
  {
    path: '/datenschutz',
    name: 'privacy',
    component: Privacy,
    meta: { pos: 5 }
  },
  {
    path: '/impressum',
    name: 'imprint',
    component: Imprint,
    meta: { pos: 6 }
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (from.meta.pos !== undefined && to.meta.pos !== undefined) {
    if (from.meta.pos < to.meta.pos) {
      Store.commit('SET_REVERSE', false)
    } else {
      Store.commit('SET_REVERSE', true)
    }
  }
  next()
})

export default router
